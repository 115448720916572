.card-order {
	.card-order__image {
		position: relative;
		margin-top: 5px;
		width: 100%;
		height: 186px;
		position: relative;
		background-size: cover;
		cursor: pointer;
		background-color: #f5f5f5;

		border-top-left-radius: 0.125rem;
		border-top-right-radius: 0.125rem;
		background-position: center;

		.card-order__image__overlay {
			position: absolute;
			left: 5px;
			top: 5px;
			padding-left: 9px;
			padding-right: 9px;
			padding-top: 4px;
			padding-bottom: 4px;
			border-radius: 2px;
			background-color: #355c7d;
			color: #f5f5f5;
			font-size: 10px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: -0.32px;
		}
	}

	.card-order__nickname {
		font-size: 12px;
		font-weight: 700;
		letter-spacing: -0.32px;
		display: flex;
		gap: 6px;
	}

	.card-order__title {
		margin-top: 10px;
		height: 36px;
		color: #0e1111;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: -0.32px;
	}

	.card-order__summary {
		margin-top: 10px;
		color: #424549;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.32px;
	}

	.card-order__type {
		margin-top: 10px;
		color: #424549;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.32px;
	}

	.card-order__date {
		margin-top: 10px;
		color: #777;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.32px;
	}

	.card-order__label {
		display: flex;

		margin-top: 10px;

		div {
			border-radius: 9.5px;
			background: #202c4a;
			padding: 3px 7px;

			color: #f5f5f5;
			font-size: 10px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: -0.32px;
		}
	}
}
