@import url("https://spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css");

// 부트스트랩 버튼 클래스 오버라이드
.btn {
	//--bs-btn-font-weight: 400 !important;

	&.final {
		--bs-btn-font-weight: 600 !important;
	}

	&:disabled {
		--bs-btn-disabled-border-color: #dadada !important;
	}

	&.active {
		--bs-btn-active-color: #0057ff !important;
		--bs-btn-active-border-color: #0057ff !important;
		--bs-btn-active-bg: transparent;
		--bs-btn-font-weight: 500 !important;
	}

	&.blue {
		--bs-btn-color: #0057ff !important;
		--bs-btn-hover-color: #0057ff !important;
		--bs-btn-hover-border-color: #0057ff !important;
	}
}
.btn-outline-primary {
	--bs-btn-border-color: #0057ff !important;
	--bs-btn-hover-color: #0057ff !important;
	--bs-btn-hover-bg: transparent !important;
	--bs-btn-active-color: #0057ff !important;
	--bs-btn-active-border-color: #0057ff !important;
	--bs-btn-active-bg: transparent !important;
}
.btn-outline-secondary {
	--bs-btn-border-color: #dadada !important;
	--bs-btn-hover-color: #424549 !important;
	--bs-btn-hover-bg: transparent !important;
	--bs-btn-active-color: #424549 !important;
	--bs-btn-active-border-color: #424549 !important;
	--bs-btn-active-bg: transparent !important;
}

body {
	margin: 0;
	font-family: "Spoqa Han Sans Neo", "sans-serif" !important;
	font-weight: 800;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f6f7f9 !important;
	--bs-body-font-weight: 500 !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
	@media (min-width: 992px) {
		max-width: 800px !important;
	}

	@media (min-width: 1200px) {
		max-width: 1200px !important;
	}
}

header {
	font-size: calc(0.5rem + 0.45vw);
	text-align: center;
	padding-bottom: 0;
	font-weight: bold;
	border-bottom: 0.75px #202c4a solid;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	.headerLogoAndIcon {
		margin: auto;
		margin-top: 1rem;
		margin-bottom: 15px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		.headerLogo {
			width: auto;
			height: 41px;

			@media (min-width: 1200px) {
				height: 60px;

				margin-top: 70px;
				margin-bottom: 71px;
			}
		}

		.userSquare {
			position: absolute;
			width: auto;
			right: 24px;
			height: 24px;
			width: 24px;

			img {
				width: 100%;
			}

			@media (min-width: 1200px) {
				right: 90px;
				height: 36px;
				width: 36px;
			}
		}
	}

	.mainNav {
		text-align: center;
		padding-bottom: 1rem;
		position: relative;

		@media (min-width: 1200px) {
			padding-bottom: 22px;
		}

		.headerItem {
			padding: 0 !important;
			word-break: keep-all;

			@media (min-width: 1200px) {
				color: var(--Black, #0e1111);
				text-align: center;
				font-family: Spoqa Han Sans Neo;
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: 140%; /* 28px */
			}
		}
	}

	.subNav {
		width: 100%;
		position: absolute;
		z-index: 999;
		padding-top: 1rem;
		background-color: rgba(32, 44, 74, 0.5);
		backdrop-filter: blur(5px);

		.col-2 {
			.headerSubitem {
				display: block;
				padding: 0;
				margin-bottom: 1.3rem;
				color: white;
				text-decoration: none;
				font-weight: 300;
				word-break: keep-all;
			}
			.headerSubitem:hover {
				color: #f5b826;
			}
		}
	}

	.active {
		color: #0057ff;
	}
}

footer {
	left: 0;
	bottom: 0;
	width: 100%;
	max-width: 100vw;
	font-size: calc(0.5rem + 0.4vw);
	margin-top: 80px;
	padding-bottom: 1rem;
	border-top: 1px solid #cccccc;

	.footerLogo {
		width: auto;
		height: calc(1.75rem + 1.5vw);
	}

	.footerRow {
		display: block !important;
		margin-bottom: 0.5rem;
		white-space: nowrap;
		div {
			display: inline;
			font-size: calc(0.5rem + 0.4vw);
		}
		.seperate {
			color: #f5b826;
			margin-left: 0.6rem;
			margin-right: 0.6rem;
		}
	}
}

// 사이트 우측하단 카카오톡 채팅 버튼
.floating-button-container {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	right: 2rem;
	bottom: 2rem;

	.popup {
		margin-bottom: 1rem;
		padding: calc(0.8rem + 0.2vw);
		position: relative;
		display: flex;
		justify-content: space-between;
		color: #0e1111;
		background-color: #fff;
		border-radius: 1rem;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
		font-size: calc(0.8rem + 0.2vw);
		z-index: 998;

		.text-wrapper {
			.popup-title {
				margin: 0;
				display: block;
				font-weight: 600;
			}

			.popup-subTitle {
				margin: 0;
				display: block;
				font-size: calc(0.6rem + 0.2vw);
			}
		}

		.close-popup-btn {
			margin-left: calc(1rem + 0.2vw);
			cursor: pointer;
			background-color: transparent;
			border: none;
			border-radius: 4px;
			font-size: calc(0.8rem + 0.2vw);

			img {
				position: absolute;
				top: calc(0.8rem + 0.2vw);
				right: calc(0.8rem + 0.2vw);
			}
		}
	}
	.floating-button {
		width: calc(2.5rem + 1.5vw);
		max-width: 3.5rem;
		height: calc(2.5rem + 1.5vw);
		max-height: 3.5rem;
		cursor: pointer;
		z-index: 999;
	}
}

.modal {
	padding: 0 !important;
}
.modal-contents {
	min-width: 70vw;
	font-size: calc(0.7rem + 0.2vw);
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-hover-underline:hover {
	text-decoration: underline;
}

.disallowDrag {
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.clickable {
	cursor: pointer;
}

.noTextDeco {
	text-decoration: none;
}

.page-selector {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.page-button {
	border: none;
	background: none;
	cursor: pointer;
	margin: 0.75rem;

	&.disabled {
		color: #a7adba !important;
	}
}

.point-blue {
	filter: invert(29%) sepia(67%) saturate(6602%) hue-rotate(217deg) brightness(98%) contrast(112%);
}

.page-button.active {
	color: #007bff;
}

.blue-overlay {
	filter: invert(41%) sepia(95%) saturate(6833%) hue-rotate(217deg) brightness(98%) contrast(111%);
}

.lineLimit1 {
	display: -webkit-inline-box !important;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.lineLimit2 {
	display: -webkit-inline-box !important;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.lineLimit3 {
	display: -webkit-inline-box !important;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.lineLimit4 {
	display: -webkit-inline-box !important;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.linkButton {
	cursor: pointer;
}

.selected-blue {
	border: 3px solid #0057ff;
}

.emphasize-blue {
	color: #0057ff;
}

.paymentButton {
	width: max-content;
	height: 2.5rem;
	padding: 0.5rem 1rem;
	border-radius: 2.5rem;
	color: #f5f5f5;
	background-color: #202c4a;

	&.disabled {
		background-color: #999999;
	}
	&.disabled-filter {
		filter: grayscale(50%);
	}
}

// 90도 회전 애니메이션 키프레임 정의
@keyframes rotateRight90 {
	to {
		transform: translateY(-50%) rotate(90deg);
	}
}
@keyframes undoRotateRight90 {
	from {
		transform: translateY(-50%) rotate(90deg);
	}
	to {
		transform: translateY(-50%) rotate(0deg);
	}
}

// -90도 회전 애니메이션 키프레임 정의
@keyframes rotateLeft90 {
	to {
		transform: translateY(-50%) rotate(-90deg);
	}
}
@keyframes undoRotateLeft90 {
	from {
		transform: translateY(-50%) rotate(-90deg);
	}
	to {
		transform: translateY(-50%) rotate(0deg);
	}
}

// 180도 회전 애니메이션 키프레임 정의
@keyframes rotate180 {
	to {
		transform: translateY(-50%) rotate(180deg);
	}
}
@keyframes undoRotate180 {
	from {
		transform: translateY(-50%) rotate(180deg);
	}
	to {
		transform: translateY(-50%) rotate(0deg);
	}
}
