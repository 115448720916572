.myp_myOrderDetailWrapper {
	h4 {
    max-width: 40rem;
		position: relative;
		font-size: calc(1rem + 0.2vw);
		align-items: center;

		.myp_make-icon {
			width: calc(1.25rem + 0.2vw);
			height: calc(1.25rem + 0.2vw);
			margin-right: 0.5rem;
		}
	}

  .pack-info {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.55rem;
    position: relative;
    width: 100%;
    max-width: 40rem;
    height: max(22.5vh, 10vw);
    max-height: calc(10rem + 3vw);
    
    .pack-info-inner {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      box-shadow: 0px 0px 8px 0px #0e111133;
      
      .imageAndTag {
        padding: max(0.5rem, 1vw);
        width: 45%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .tag {
          position: absolute;
          top: max(1rem, 1.5vw);
          left: max(1rem, 1.5vw);
          padding: 0.25rem 0.5rem 0.25rem 0.5rem;
          color: #f5f5f5;
          background-color: #355c7d;
          font-size: calc(0.5rem + 0.2vw);
        }
      }
      .imageAndTag-noImage {
        padding: max(0.5rem, 1vw);
        width: 45%;
        height: 100%;
        display: flex;
        background: #ECECEC;
        background-clip: content-box;

        img {
          margin: auto;
          width: 100%;
          max-width: min(35vw, 5rem);
          height: 100%;
          object-fit: cover;
          align-self: center;
        }
        .tag {
          position: absolute;
          top: max(1rem, 1.5vw);
          left: max(1rem, 1.5vw);
          padding: 0.25rem 0.5rem 0.25rem 0.5rem;
          color: #f5f5f5;
          background-color: #355c7d;
          font-size: calc(0.5rem + 0.2vw);
        }
      }

      .textInfoArea {
        width: 55%;
        height: 100%;
        padding: max(1rem, 1vw);
        padding-left: 0.5rem;
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: calc(0.15rem + 0.2vw);

        .information {
          display: flex;
          color: #0E1111;
          font-size: calc(0.65rem + 0.2vw);
          justify-content: left;
        }
        .period {
          display: flex;
          color: #777777;
          font-size: calc(0.65rem + 0.2vw);
          justify-content: left;
        }
        .price {
          margin-top: calc(0.75rem + 0.2vw);
          display: flex;
          color: #202c4a;
          font-size: calc(0.65rem + 0.2vw);
          font-weight: 700;
          justify-content: right;
        }
        .button-area {
          margin-top: auto;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
    
          button {
            width: 100%;
            font-size: calc(0.65rem + 0.2vw);
          }
        }
      }
      hr {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }

  .optionBox {
    margin: auto;
    padding: 0.25rem;
    padding-bottom: 1rem;
    width: 100%;
    max-width: 40rem;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
  
    .subOption {
      width: 70%;
      height: max-content;
      display: flex;
      flex: 1 0 auto;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: left;
      color: #777777;
      font-size: calc(0.75rem + 0.2vw);
  
      .btn {
        width: 40%;
      }
  
      &.checkbox-list {
        padding: 1rem;
        flex-wrap: wrap;
        justify-content: left;
        column-gap: 1.25rem;
        border: 1px solid #DADADA;
        background-color: white;
        max-height: 20vh;
        overflow-y: scroll;
  
        input {
          margin-right: 0.35rem;
        }
      }
    }
  
    .nights {
      display: inline-block;
    }
  
    .blue {
      color: #0057FF;
      font-size: 1.1em;
      font-weight: 600;
    }
  
    .left-side {
      margin-right: auto;
      display: inline-block;
  
      h4 {
        display: inline;
        font-size: calc(0.85rem + 0.2vw);
        font-weight: 600;
      }
  
      .necessary {
        color: red;
      }
    }
  
    .label {
      width: 30%;
      display: flex;
      margin-bottom: 0.75rem;
      font-size: calc(0.75rem + 0.2vw);
  
      .left-side {
        margin-right: auto;
      }
      .right-side {
        margin-left: auto;
        display: inline-block;
        font-size: calc(0.75rem + 0.2vw);
      }
    }
  
    .input-text {
      width: 100%;
      //max-width: 95vw;
      height: 2.5rem;
      display: block;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 0.125rem;
      border: 1px solid #dadada;
      background-color: white; //#f6f7f9;
  
      &.half {
        width: 50%;
        display: inline;
      }
  
      &.textarea {
        padding: 0.75rem;
        height: 10rem;
      }
    }
  
    .input-text-disabled {
      width: 100%;
      //max-width: 95vw;
      height: 2.5rem;
      display: block;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 0.125rem;
      border: 1px solid #999999;
      color: #777777;
      background-color: #ECECEC;
      cursor: not-allowed;
  
      &.half {
        width: 50%;
        display: inline;
      }
    }
  
    .range-buttons {
      margin-bottom: 0.5rem;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 0.5rem;
  
      .range-button {
        padding: 0.35rem;
        font-size: calc(0.8rem + 0.2vw);
        text-align: center;
        width: 100%;
      }
    }
  
    .coverImagePreview {
      width: 100%;
      //max-width: 95vw;
      height: 30vh;
      //line-height: 30vh;
      text-align: center;
      display: block;
      margin-bottom: 0.5rem;
      border-radius: 0.125rem;
      border: 1px solid #999999;
      color: #777777;
      background-color: #ECECEC;
      margin-bottom: 1rem;
      background-size: contain;
  
      .coverImagePreviewText {
        position: absolute;
        z-index: -1;
      }
  
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  
    .check-before-submit {
      font-size: calc(0.75rem + 0.2vw);
      margin-bottom: 1rem;
    }
  
		.confirm-box-wrapper {
			display: flex;
			justify-content: left;
			align-items: center;

			#confirmed-check-message {
				width: calc(0.9rem + 0.2vw);
				height: calc(0.9rem + 0.2vw);
			}
			#confirmed-check-message-label {
				font-size: calc(0.9rem + 0.2vw);
				margin-left: 1rem;
			}
		}
  }

  .google-map-country {
    width: 100%;
    //max-width: 95vw;
    height: 100%;
    min-height: 35vh;
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    padding-right: 1rem;
    border-radius: 0.125rem;
    border: 1px solid #dadada;
    background-color: white; //#f6f7f9;
  }

  .btn {
    margin: auto;
    display: block;
    max-width: 40rem;
    margin-bottom: 1rem;
  }
}

// myp_myOrderPayment.tsx
.estimate-confirm-modal-title {
  width: 100%;
  display: block;
  text-align: center;

  .icon {
    font-size: calc(1.5rem + 0.4vw);

    &.big {
      font-size: calc(2rem + 0.4vw);
    }
  }

  .text {
    font-size: calc(0.7rem + 0.2vw);
    font-weight: 400;

    &.big {
      margin-top: 0.75rem;
      margin-bottom: 1rem;
      color: #0057FF;
      font-size: calc(1.15rem + 0.4vw);
      font-weight: 700;
    }
    &.bold {
      margin-bottom: 0.5rem;
      font-size: calc(0.7rem + 0.2vw);
      font-weight: 700;
    }
  }
}

.estimate-confirm-modal-wrapper {
  width: 100%;
  display: block;
  text-align: center;

  .icon {
    color: #FF0000;
    font-size: calc(2rem + 0.4vw);
  }

  .text {
    font-size: calc(0.7rem + 0.2vw);
    font-weight: 400;

    &.big {
      margin-top: 0rem;
      margin-bottom: 1rem;
      color: #FF0000;
      font-size: calc(1.15rem + 0.4vw);
      font-weight: 700;
    }
    &.bold {
      margin-bottom: 0.5rem;
      font-size: calc(0.7rem + 0.2vw);
      font-weight: 700;
    }
  }
}