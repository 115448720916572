.entireCustomerRegisterWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	h4 {
		margin-top: 3rem;
		margin-bottom: 3rem;
		font-weight: 700;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: center;

		#genderButtonWrapper {
			width: 22.5rem;
			padding: 0.5rem 0rem 0.5rem 0rem;
			margin: 0;
			.col-6 {
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				margin-bottom: 1rem;
				button {
					width: 100%;
				}
			}
		}

		#travelPreferenceButtonWrapper {
			width: 22.5rem;
			padding: 0.5rem 0rem 0.5rem 0rem;
			margin: 0;
			.col-6 {
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				margin-bottom: 1rem;
				button {
					width: 100%;
				}
			}
		}

		#preferenceButtonWrapper {
			width: 22.5rem;
			padding: 0.5rem 0rem 0.5rem 0rem;
			margin: 0;
			.col-6 {
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				margin-bottom: 1rem;
				button {
					width: 100%;
				}
			}
		}

		.buttonWrapper {
			width: 22.5rem;
			padding: 0.5rem 0rem 0.5rem 1rem;
			margin: 0;

			button {
				width: 100%;
			}
		}

		.inputLabel {
			margin-top: 0.5rem;
			padding-left: 0.5rem;
			color: #0e1111;
			font-size: 1rem;
			font-weight: 700;
			text-align: left;
			letter-spacing: -0.02rem;
		}

		.input_text {
			width: 22.5rem;
			max-width: 95vw;
			height: 2.5rem;
			display: block;
			margin: 0.5rem;
			padding-left: 1rem;
			padding-right: 1rem;
			border-radius: 0.125rem;
			border: 1px solid #dadada;
			background-color: white; //#f6f7f9;
		}

		.input_text-disabled {
			width: 22.5rem;
			max-width: 95vw;
			height: 2.5rem;
			display: block;
			margin: 0.5rem;
			padding-left: 1rem;
			padding-right: 1rem;
			border-radius: 0.125rem;
			border: 1px solid #999999;
			color: #777777;
			background-color: #ECECEC;
			margin-bottom: 1rem;
			cursor: not-allowed;
		}

		.input_text-invalid {
			margin-left: 1rem;
			color: red;
			text-align: left;
			font-size: 0.75rem;
			margin-bottom: 1rem;
		}

		.checkbox {
			margin-right: 0.5rem;
		}

		.checkboxAndTextarea {
			width: 22.5rem;
			margin: 0.5rem;
			text-align: left;
			margin-top: 2rem;
			margin-bottom: 1rem;

			textarea {
				width: 100%;
				max-width: 95vw;
				height: 8.125rem;
				display: block;
				margin-bottom: 0.5rem;
				padding-left: 1rem;
				padding-right: 1rem;
				border-radius: 0.125rem;
				border: 1px solid #dadada;
				background-color: white;
				
			}
		}

		.necessary {
			color: red;
		}

		.small-information {
			color: #777;
			margin-left: 0.35rem;
			font-size: 0.75rem;
			font-weight: 400;
		}

		hr {
			width: 22.5rem;
		}
	}
}
