.intro-banner {
	width: 100%;
	height: 230px;

	@media (min-width: 992px) {
		height: 340px;
	}

	@media (min-width: 1200px) {
		height: 550px;
	}

	position: relative;
	padding: 1rem;
	background: rgba(14, 17, 17, 0.3);
	display: flex;
	align-items: center;
	justify-content: center;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
}

.banner-content {
	position: relative;
	width: 100%;

	display: flex;
	@media (min-width: 1200px) {
		max-width: 1200px;
	}
}

.banner-title {
	text-align: start;
	z-index: 1;
	margin-top: 1rem;

	color: var(--White, #f5f5f5);
	font-family: Spoqa Han Sans Neo;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.32px;

	@media (min-width: 1200px) {
		text-align: start;
		color: var(--White, #f5f5f5);
		font-family: Spoqa Han Sans Neo;
		font-size: 40px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: -0.32px;
	}
}

.banner-text {
	text-align: start;

	color: var(--White, #f5f5f5);
	font-family: Spoqa Han Sans Neo;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.32px;

	@media (min-width: 1200px) {
		margin-top: 22px;

		color: var(--White, #f5f5f5);
		font-family: Spoqa Han Sans Neo;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: -0.32px;
	}

	z-index: 1;
}

.banner-caption {
	text-align: center;
	color: #f5f5f5;
	font-size: 14px;
	font-weight: 500;
	z-index: 1;
}

.categories {
	width: 100%;
	position: relative;
	overflow-x: auto;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	list-style: none;
	margin-bottom: 1rem;
	scrollbar-width: none;
	-ms-overflow-style: none; // Internet Explorer 10+

	.category-item {
		width: auto;
		min-width: max-content;
		flex: 0 0 auto;
		height: 100%;
		position: relative;
		background: #f6f7f9;
		text-align: center;
		vertical-align: middle;
		color: #0e1111;
		font-size: calc(0.75rem + 0.3vw);
		font-weight: 500;
		word-wrap: break-word;
		padding: 0.75em;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
		border-bottom: 3px solid transparent;

		&.selected {
			color: #0057ff;
			border-bottom: 2px #0057ff solid;
		}
	}
}

.categories::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.topBar {
	height: 3rem;
	#recommendedScheduleWrapper {
		vertical-align: middle;
		justify-content: center;
		font-size: calc(0.75rem + 0.2vw);

		#recommendedScheduleName {
			margin-right: 0.5rem;
		}

		#recommendedScheduleCount {
			font-size: 0.875rem;
			font-weight: 500;
			color: #0057ff;
		}

		div {
			display: inline-block;
		}
	}

	.searchFormWrapper {
		padding: 0;

		.search-form {
			width: fit-content;
			height: fit-content;
			position: relative;
			background: #f6f7f9;
			border-radius: 2px;

			.search-input {
				color: #0e1111;
				font-size: 1rem;
				font-weight: 400;
				word-wrap: break-word;
			}

			.search-icon {
				width: 22px;
				height: 22px;
				position: absolute;
				right: 10px;
				top: 7px;
				cursor: pointer;
			}
		}
	}
}

.grid-card {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	column-gap: 14px;
	row-gap: 20px;

	@media (min-width: 992px) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}

.card {
	width: 100%;
	max-width: 48vw;
	//height: 17.5rem;  //23.5625rem;
	background: #f6f7f9;
	box-shadow: 0px 0px 8px rgba(14, 17, 17, 0.2);
	border-radius: 0.125rem;
	border: 0.03125rem #dadada solid;
	grid-template-rows: 55% auto;
	position: relative;

	// 드래그 방지
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	&.crew_recommend {
		box-shadow: none;
		border: none;

		.card_image {
			height: 186px;
			width: auto;
		}
	}

	.card_image {
		width: 100%;
		height: 65%;
		position: relative;
		border-top-left-radius: 0.125rem;
		border-top-right-radius: 0.125rem;
		cursor: pointer;
		background-color: #f5f5f5;
		background-size: cover;

		.card_tag {
			position: absolute;
			top: 5px;
			left: 5px;
			padding: 0.3rem 0.6rem 0.3rem 0.6rem;
			border-radius: 0.2rem;
			color: #f5f5f5;
			font-size: calc(0.4rem + 0.4vw);
			font-weight: 500;
		}

		.card_rating {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 5rem;
			padding: 0.15rem 3.5rem 0.15rem 2.5rem;
			border-radius: 0 1.35rem 0 0;
			color: #0e1111;
			background-color: rgb(245, 245, 245, 0.6);
			font-size: calc(0.5rem + 0.4vw);
			font-weight: 500;
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: default;

			.icon {
				display: flex;
				font-size: 1.15rem;
				margin-right: 0.5rem;
			}

			.rating {
				display: flex;
				font-size: 0.9rem;
			}
		}
	}

	.card_textBox {
		background-color: #f6f7f9;
		overflow: hidden;

		.card_title {
			color: #0e1111;
			padding-top: 10px;

			font-size: 14px;
			font-weight: 700;
			display: -webkit-inline-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			letter-spacing: -0.32px;
		}

		.card_description {
			padding-top: 10px;
			color: #0e1111;
			font-size: 12px;
			font-weight: 400;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			letter-spacing: -0.32px;
		}

		.card_button_group {
			padding-top: 10px;
			display: flex;
			gap: 10px;
			.card_button {
				flex: 1 1 0%;
				letter-spacing: -0.32px;
				color: #0057ff;
				text-align: center;
				padding-top: 8px;
				padding-bottom: 8px;
				border-radius: 2px;
				font-size: 12px;
				font-weight: 700;
				border: 1px solid var(--Ssub1, #dadada);
				background: var(--White, #f5f5f5);
			}
		}
	}
}
