.card-community-clup {
	border-radius: 2px;
	border: 1px solid var(--Ssub1, #dadada);
	background: var(--BG, #f6f7f9);
	box-shadow: 0px 0px 8px 0px rgba(14, 17, 17, 0.2);

	.card-community-clup__image {
		position: relative;
		width: 100%;
		height: 186px;
		position: relative;
		background-size: cover;
		cursor: pointer;
		background-color: #f5f5f5;

		border-top-left-radius: 0.125rem;
		border-top-right-radius: 0.125rem;
		background-position: center;

		.card-community-clup__image__overlay {
			position: absolute;
			left: 5px;
			top: 5px;
			padding-left: 9px;
			padding-right: 9px;
			padding-top: 4px;
			padding-bottom: 4px;
			border-radius: 2px;
			background-color: #355c7d;
			color: #f5f5f5;
			font-size: 10px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: -0.32px;
		}
	}

	.card-community-clup__top {
		padding: 6px;
		display: flex;
		align-items: center;
		gap: 5px;

		.card-community-clup__profile {
			width: 21px;
			height: 21px;
			border-radius: 9999px;
			overflow: hidden;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 100%;
				height: 100%;
			}
		}
		.card-community-clup__nickname {
			color: #0e1111;
			font-size: 10px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: -0.32px;
		}
		.card-community-clup__label {
			flex: 1 1 auto;
			display: flex;
			justify-content: flex-end;

			div {
				display: flex;
				justify-content: center;
				align-items: center;

				width: 50px;
				height: 21px;

				border-radius: 3px;

				color: #f5f5f5;
				font-size: 10px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;

				&.recruitment {
					background-color: #f67280;
				}

				&.planned {
					background-color: #a870b1;
				}
			}
		}
	}

	.card-community-clup__title {
		margin-top: 10px;
		height: 36px;

		padding: 0 5px;

		color: #202c4a;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: -0.32px;
	}

	.card-community-clup__summary {
		margin-top: 10px;

		padding: 0 5px;
		color: #424549;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.32px;
	}

	.card-community-clup__date {
		margin-top: 10px;

		padding: 0 5px;

		color: #777;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: -0.32px;
	}

	.card-community-clup__bottom {
		margin-top: 10px;
		color: #0057ff;

		padding: 0 5px;

		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: -0.32px;
	}

	.card_community_clup__counts {
		margin-top: 20px;
		padding-bottom: 9px;

		padding-left: 5px;
		padding-right: 5px;

		display: flex;
		justify-content: flex-end;
		align-content: center;
		gap: 15px;

		.item {
			display: flex;
			align-items: center;
			gap: 5px;
			svg {
				width: 16px;
				height: 16px;
			}

			color: #0e1111;
			font-size: 10px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: -0.32px;
		}
	}
}
