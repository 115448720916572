.notice-categories {
  width: auto;
  position: relative;
  overflow-x: auto;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  list-style: none;
  margin-bottom: 1rem;
  scrollbar-width: none;
  -ms-overflow-style: none; // Internet Explorer 10+

  .category-item {
    width: auto;
    min-width: max-content;
    flex: 0 0 auto;
    height: 100%;
    position: relative;
    background: #f6f7f9;
    text-align: center;
    vertical-align: middle;
    color: #0e1111;
    font-size: calc(0.75rem + 0.3vw);
    font-weight: 500;
    word-wrap: break-word;
    padding: 0.75em;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-bottom: 3px solid transparent;

    &.selected {
      color: #0057ff;
      border-bottom: 2px #0057ff solid;
    }
  }
}
.notice-categories::-webkit-scrollbar {
  width: 0;
  height: 0;
}