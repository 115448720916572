@use "../color";

.omt_brandStoryWrapper {
  .brand-story-header {
		width: 100%;
		margin: 4rem 0 3rem;
		display: flex;
    flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;

		.border-line {
			flex: 1 0 auto;
			height: 0;
			border: 0.25px #bbbbbb solid;
			display: inline-block;
		}

    .brand-story-header-text {
      margin-left: 3rem;
      margin-right: 3rem;
      font-size: calc(1.5rem + 0.2vw);
    }
	}

  .row {
    .col-12 {
      height: max-content;
    }
    
    hr {
      margin-top: max(2rem, 2vw);
      margin-bottom: max(2rem, 2vw);
      //width: 100%;
    }
  }
}

.brandStory {
  img {
    width: 100%;
  }
}

.brandStory-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;

  .point-title {
    font-size: calc(1.5rem + 0.2vw);
    font-weight: 800;
    color: color.$point-1;
    margin-bottom: calc(0.5rem + 0.2vw);
  }
  .quote {
    font-size: calc(1.15rem + 0.2vw);
    font-weight: 500;
    color: color.$gray-2;
    margin-bottom: 1.5rem;
  }
  .story {
    font-size: calc(0.8rem + 0.2vw);
    color: color.$gray-1;
    margin-bottom: 1.5rem;
  }
}

.brandStory-wide {
  text-align: center;
  font-size: calc(1.5rem + 0.2vw);
  font-weight: 400;

  .upperText {
    margin-bottom: 1rem;
    font-size: calc(1.75rem + 0.2vw);
    font-weight: 700;
  }
}