.communityRecruitWrapper {
	.upperBanner {
		padding-top: calc(min(7.5vh, 1.5rem));
		padding-bottom: calc(min(7.5vh, 1rem));
    position: relative;
		text-align: left;
    display: flex;
    text-align: left;
    justify-content: left;
    font-weight: 500;

		.information {
			font-size: calc(0.75rem + 0.5vw);
			font-weight: 400;
		}

    img {
      margin-left: 0.5rem;
    }

    .overlay {
        position: absolute;
        width: 90vw;
        max-width: 25rem;
        top: 3rem;
        left: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

      .overlay-inner {
          padding: 1.5rem;
          width: 90vw;
          min-width: max-content;
          max-width: 25rem;
          font-size: calc(0.75rem + 0.2vw);
          color: #F5F5F5;
          background: #202C4AD4;
          backdrop-filter: blur(4px);
          box-shadow: 0px 0px 8px 0px #11111154;
          border: 1px solid #F5F5F5;
          border-radius: 0.25rem;
          z-index: 3;
      }
    }
	}

  .searchFormWrapper {
		margin-top: 0.5rem;
    margin-bottom: 1.5rem;
		padding: 0;
		max-width: 90vw;
    display: flex;
    text-align: left;
    justify-content: left;

		.search-form {
			position: relative;
			background: #f6f7f9;
      width: 95%;

			.search-input {
				width: 100%;
				height: 2.5rem;
				color: #0e1111;
				font-size: calc(0.75rem + 0.2vw);
				font-weight: 400;
				word-wrap: break-word;
				border: 1px solid #dadada;
				border-radius: 0.25rem;
			}

			.search-input::placeholder {
				color: #999999;
			}

			.search-icon {
				width: 1.5rem;
				height: 1.5rem;
				position: absolute;
				right: 1rem;
				top: 7px;
				cursor: pointer;
			}
		}

    .filterButton {
      width: 12.5vw;
      max-width: 3rem;
      margin-left: 1rem;

      img {
        height: 75%;
      }
    }
	}

  .filterPanel {
    position: relative;

    .filterCategory {
      margin-bottom: 1.5rem;
      position: relative;
      
      h4 {
        font-size: calc(0.85rem + 0.2vw);
        font-weight: 500;
      }

      .range-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .range-button {
          padding: 0.25rem;
          font-size: calc(0.7rem + 0.2vw);
          text-align: center;
          width: 9.5%;
        }
      }

      .gender-select {
        padding: 0.25rem;
        width: 100%;
        font-size: calc(0.7rem + 0.2vw);
        border: 1px solid #DADADA;
        background-color: #F6F7F9;
      }

      .age-buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.25rem;
        .age-button {
          width: 20%;
          padding: 0.25rem;
          font-size: calc(0.7rem + 0.2vw);
          text-align: center;
        }
      }

      .option-buttons {
        .d-flex > button {
          font-size: calc(0.7rem + 0.2vw);
        }
      }
    }

    .setButton {
      flex: 1 1 auto;;
      margin-bottom: 2rem;
      margin-right: calc(var(--bs-gutter-x) * 0.5);
      margin-left: calc(var(--bs-gutter-x) * 0.5);
    }
  }
}

.travel-post {
  .insideWrapper {
    border-radius: 0.25rem;
    //padding: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.3);
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
  margin-bottom: 0.75rem;

  .post-image {
    width: 100%;
    height: 100%;
    padding-bottom: 100%;  // 이미지를 정사각형 비율로 만들기 위함
    position: relative;
    background-size: cover;

    .post-tag {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      padding: 0.25rem 0.5rem 0.25rem 0.5rem;
      color: #F5F5F5;
      background-color: #355C7D;
      font-size: calc(0.5rem + 0.2vw);;
    }
  }
}

.uploader-info {
  position: relative;
	display: flex;
  height: max-content;
  vertical-align: middle;
	align-items: center;
  margin-bottom: 0.5rem;

	.profile-image {
		width: calc(1.5rem + 0.2vw);
		height: calc(1.5rem + 0.2vw);
		border-radius: 50%;
		margin-right: 10px;
	}

	.nickname {
    width: 40%;
    font-size: calc(0.5rem + 0.2vw);
		font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
	}

  .class-tag {
    position: absolute;
    top: 0.25rem;
    right: 0rem;
    padding: 0.15rem 0.35rem 0.15rem 0.35rem;
    color: #F5F5F5;
    background-color: #F67280;
    border-radius: 0.25rem;
    font-size: calc(0.5rem + 0.2vw);
    display:flex;
    text-align: left;
    justify-content: left;

    img {
      margin-right: 0.25rem;
    }
  }

  .class-tag-planning {
    position: absolute;
    top: 0.25rem;
    right: 0rem;
    padding: 0.15rem 0.35rem 0.15rem 0.35rem;
    color: #F5F5F5;
    background-color: #A870B1;
    border-radius: 0.25rem;
    font-size: calc(0.5rem + 0.2vw);
    display:flex;
    text-align: left;
    justify-content: left;

    img {
      margin-right: 0.25rem;
    }
  }
}

.destination {
	color: #333;
	margin-top: 10px;
}

.title,
.people,
.recruitment-period,
.gender,
.age-group,
.briefInformation,
.viewAndComments {
	font-size: calc(0.6rem + 0.2vw);
  display:flex;
  text-align: left;
  justify-content: left;
}

.briefInformation {
  margin-top: 0.5rem;
}

.viewAndComments {
  justify-content: right;

  img {
    margin-right: 0.5rem;
  }

  .seperator {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}

.briefInformation,
.recruitment-period {
  color: #777777;
}

.title {
  height: calc(2rem + 0.4vw);
  font-weight: 700;
}

.people {
  font-weight: 700;
  color: #0057FF;
}

.travel-tendencies,
.travel-preferences {
	list-style-type: none;
	padding-left: 0;

	li {
		display: inline-block;
		margin-right: 5px;
		padding: 2px 5px;
		border: 1px solid #ccc;
		font-size: 12px;
	}
}
