.section-comming-soon {
	.section-comming-soon__title {
		color: #000;

		margin-top: 21px;

		text-align: center;
		font-family: Spoqa Han Sans Neo;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: -0.32px;
	}

	.section-comming-soon__sub_title {
		color: #000;

		margin-top: 10px;

		text-align: center;
		font-family: Spoqa Han Sans Neo;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: -0.32px;
	}

	.section-comming-soon__image {
		margin-top: 20px;
		width: 100%;
		overflow: hidden;
		img {
			width: 100%;
			transform: scale(1.5);

			@media (min-width: 1200px) {
				transform: scale(1);
				max-width: 706px;
			}
		}
	}
}
