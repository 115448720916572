// 색상 변수의 정의
$main: #F5B826;
$main-2: #202C4A;
$background: #F6F7F9;

$point-1: #0057FF;

$gray-1: #0E1111;
$gray-2: #424549;
$gray-3: #777777;
$gray-4: #999999;
$gray-5: #F5F5F5;
$gray-6: #ECECEC;

$sgray-1: #343D46;
$sgray-2: #4F5B66;
$sgray-3: #65737E;
$sgray-4: #A7ADBA;
$sgray-5: #C0C5CE;

$point-sub-1: #3866d9;
$point-sub-2: #347a43;
$point-sub-3: #f5b826;
$point-sub-4: #a62c2c;
$point-sub-5: #8e3962;
$point-sub-6: #d95830;
$point-sub-7: #355c7d;
$point-sub-8: #f67280;
$point-sub-9: #a870b1;