.howToUseCommunityWrapper {
	.banner {
		padding-top: 4rem;
		padding-bottom: 1rem;
		text-align: center;

		h2 {
			font-weight: 700;
		}

    img {
      margin: auto;
      width: 92.5vw;
      max-width: 22.5rem;
      display: block;
    }

    ul {
      margin: auto;
      width: 90vw;
      max-width: 30rem;
      display: block;
      text-align: left;
      list-style-type: "✔ ";

      li {
        font-size: calc(0.85rem + 0.3vw);
      }
    }

    button {
      max-width: 30rem;
    }

		.information {
			font-size: calc(0.75rem + 0.5vw);
			font-weight: 400;
		}
	}
}