.asm_participateEntireWrapper {
  .upperBanner {
    width: 100%;
    position: relative;
    padding: 1rem;
    padding-top: min(10vh, 5rem);
    padding-bottom: min(10vh, 5rem);
    margin-bottom: 1rem;
    color: #f5f5f5;
    background: rgba(14, 17, 17, 0.5);
    font-size: calc(0.85rem + 0.2vw);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../image/asm_participate_upperBanner_background.png) no-repeat center center / cover;
      filter: blur(2px);
      z-index: -1;
    }
  }
}

.asm_participateWrapper {
  .upper-information-header {
    display: flex;
    justify-content: space-between;
  }
  .button {
    font-size: 12px; 
    display: flex;
    align-items: center;

    img {
      width: 18px;
      height: 18px;
    }
  }
  
  // 버튼 영역 공통 스타일
  .upper-information,
  .hot-packs,
  .ai-recommended {
    .button-area {
      margin: auto;
      width: 100%;
      max-width: 40rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: calc(0.5rem + 1vw);

      .btn {
        width: 100%;
      }
    }
  }

  h4 {
    display: flex;
    align-items: center;
		position: relative;
		font-size: 16px;
		display: flex;
    font-weight: 700;
		align-items: center;
    margin-bottom: 0px;
		img {
      position: relative;
      bottom:1px;
			width: 18px;
			height: 18px;
			margin-right: 0.5rem;
		}
  }

  .category-explain {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    .example-category-tag {
      margin-right: calc(0.75rem + 0.5vw);
      padding: 0.25rem 0.5rem;
      display: flex;
      align-items: center;
      color: #F5F5F5;
      border-radius: 0.5rem;
      font-size: calc(0.8rem + 0.2vw);
      font-weight: 400;
      white-space: nowrap;

      &.recruit {
        background-color: #F67280;
      }
      &.plan {
        background-color: #A870B1;
      }

      img {
        width: calc(0.8rem + 0.2vw);
        height: calc(0.8rem + 0.2vw);
        margin-right: 0.25rem;
      }
    }
    .category-info {
      font-size: calc(0.65rem + 0.2vw);
      display: inline-block;
    }
  }
}
