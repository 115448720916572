.omt-sub-title {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.omt-sub-title_title {
		span {
			display: flex;
			align-items: center;
			gap: 4px;
		}

		img {
			position: relative;
			bottom: 1px;

			@media (min-width: 1200px) {
				width: 30px;
				height: 30px;
			}
		}

		color: var(--Main2, #202c4a);
		font-family: Spoqa Han Sans Neo;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 21px; /* 131.25% */
		letter-spacing: -0.32px;

		@media (min-width: 1200px) {
			color: var(--Main2, #202c4a);
			font-family: Spoqa Han Sans Neo;
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: 21px; /* 87.5% */
			letter-spacing: -0.32px;
		}
	}

	.omt-sub-title_sub {
		div.button {
			display: flex;
			align-items: center;
		}

		img {
			@media (min-width: 1200px) {
				bottom: 2px;

				width: 28px;
				height: 28px;
			}
		}

		color: var(--Sub1, #424549);
		text-align: right;
		font-family: Spoqa Han Sans Neo;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 166.667% */

		@media (min-width: 1200px) {
			color: var(--Sub1, #424549);
			text-align: right;
			font-family: Spoqa Han Sans Neo;
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 100% */
		}
	}
}
