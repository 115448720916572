.omt_tab {
	margin-left: 23px;

	display: flex;
	gap: 8px;

	.omt_tab_item {
		margin-top: 15px;
		width: 50px;
		text-align: center;
		flex: 0 0 auto;
		height: 100%;

		color: #202c4a;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px; /* 166.667% */

		padding-bottom: 2px;
		border-bottom: 2px solid transparent;

		transition: all 0.3s;

		&.active {
			border-bottom: 2px solid #f5b826;
			color: #202c4a;
			font-size: 12px;
			font-style: normal;
			font-weight: 700;
			line-height: 20px; /* 166.667% */
		}

		span {
			display: flex;
			justify-content: center;
			gap: 4px;
		}
	}
}
